import React from 'react';
import Papa from 'papaparse';

const ParsePage = () => {

    const parse = (e) => {
        const file = e.target.files[0];
        Papa.parse(file, {
            skipEmptyLines: true,
            header: true,
            complete: (r, f) => {
                let countriesByCode = {};
                for (let i in r.data) {
                    const country = r.data[i];
                    countriesByCode[country.code] = country.name;
                }
                Papa.parse(document.getElementById('file-input-1').files[0], {
                    skipEmptyLines: true,
                    header: true,
                    complete: (r, f) => {
                        const airportsFinal = [];
                        const airportData = r.data;
                        const airportByCountryAndCity = {
                            "United Kingdom" : {
                                "London": [
                                    {"name":"London, United Kingdom (All airports)", iata_code:"LON", overrideAggregate: true},
                                    {"name":"London City Airport", iata_code:"LCY"}
                                ]
                            },
                            "Antigua and Barbuda" : {
                                "Antigua": [
                                    {"name":"V.C. Bird International Airport", iata_code:"ANU"}
                                ]
                            },
                            "Barbados" : {
                                "Barbados": [
                                    {"name":"Barbados", iata_code:"BGI"}
                                ]
                            },
                            "Grenada" : {
                                "Grenada": [
                                    {"name":"Maurice Bishop International Airport", iata_code:"GND"}
                                ]
                            },
                            "Jamaica": {
                                "Montego Bay": [
                                    {"name":"Montego Bay Sangster International Airport", iata_code:"MBJ"}
                                ]
                            },
                            "Japan": {
                                "Tokyo": [
                                    {"name":"Tokyo, Japan (All airports)", iata_code:"TYO", overrideAggregate: true},
                                ]
                            },
                            "United States": {
                                "New York": [
                                    {"name":"New York, United States (All airports)", iata_code:"NYC", overrideAggregate: true},
                                ],
                                "Washington": [
                                    {"name": "Washington, United States (All airports)", iata_code:"WAS", overrideAggregate: true}
                                ]
                            },
                            "Saint Lucia": {
                                "Saint Lucia": [
                                    {"name":"St Lucia Hewanorra International Airport", iata_code:"UVF"}
                                ]
                            },
                            "Trinidad and Tobago": {
                                "Tobago": [
                                    {"name":"ANR Robinson International Airport", iata_code:"TAB"}
                                ]
                            },
                        };
                        const codeSeen = {};
                        for (let j in airportData) {
                            let airport = airportData[j];
                            if (airport.iata_code !== "" && !codeSeen.hasOwnProperty(airport.iata_code) && airport.type === "large_airport") {
                                if (!codeSeen.hasOwnProperty(airport.iata_code)) {
                                    airport.country = countriesByCode[airport.iso_country].trim();
                                    if (!airportByCountryAndCity.hasOwnProperty(airport.country)) {
                                        airportByCountryAndCity[airport.country] = {};
                                    }
                                    airport.municipality = airport.municipality.trim();
                                    if (airport.municipality !== "") {
                                        if (!airportByCountryAndCity[airport.country].hasOwnProperty(airport.municipality)) {
                                            airportByCountryAndCity[airport.country][airport.municipality] = [];
                                        }
                                        airportByCountryAndCity[airport.country][airport.municipality].push(airport);
                                        codeSeen[airport.iata_code] = true;
                                    } else {
                                        console.log('skipping, has no city name', airport.name, airport);
                                    }
                                } else {
                                    console.log('skipping, already seen iata code', airport.name, airport);
                                }
                            }
                        }
                        for (let countryName in airportByCountryAndCity) {
                            for (let cityName in airportByCountryAndCity[countryName]) {
                                const airports = airportByCountryAndCity[countryName][cityName];
                                const hasMoreThanOneAirport = airports.length > 1;
                                if (hasMoreThanOneAirport) {
                                    if (airports[0].overrideAggregate) {
                                        airportsFinal.push([airports[0].name, airports[0].iata_code]);
                                        airports.splice(0, 1);
                                    } else {
                                        const allIATAs = [];
                                        for (let z in airports) {
                                            allIATAs.push(airports[z].iata_code);
                                        }
                                        airportsFinal.push([cityName + ', ' + countryName + ' (All airports)', allIATAs.join(':')]);
                                    }
                                }
                                for (let z in airports) {
                                    const airport = airports[z];
                                    const newRow = [
                                        hasMoreThanOneAirport ? (airport.name + ', ' + cityName + ' (' + airport.iata_code + ')') : (cityName + ', ' + countryName + ' (' + airport.iata_code + ')'),
                                        airport.iata_code
                                    ];
                                    if (!hasMoreThanOneAirport) {
                                        newRow.push(airport.name);
                                    }
                                    if (countryName === 'United Kingdom' && cityName === 'London') {
                                        airportsFinal.splice(1, 0, newRow); // insert before ldn city airport
                                    } else {
                                        airportsFinal.push(newRow);
                                    }
                                }
                            }
                        }
                        console.log(JSON.stringify(airportsFinal));
                    }
                });
            }
        });
    };

    return (
        <React.Fragment>
            <input type="file" id="file-input-1"/>
            <input type="file" id="file-input-2" onChange={parse}/>
        </React.Fragment>
    );
};

export default ParsePage;