import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import { AuthUserContext } from '../Session';

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const Navigation = () => (
    <div>
        <AuthUserContext.Consumer>
            {authUser =>
                authUser ? <NavigationAuth authUser={authUser} /> : <NavigationNonAuth />
            }
        </AuthUserContext.Consumer>
    </div>
);

const Link1 = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const NavigationAuth = ({ authUser }) => {
    const classes = useStyles();
    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    <Link color="inherit" component={Link1} to="/" underline="none">
                        Find
                    </Link>
                </Typography>
                <Button color="inherit" component={Link1} to="/signin" >Login</Button>
            </Toolbar>
        </AppBar>
    );
};
    /*<Navbar collapseOnSelect bg="primary" expand="md" variant="dark">
        <Container>
            <LinkContainer to={ROUTES.LANDING}>
                <Navbar.Brand>Virgin Reward</Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <LinkContainer to={ROUTES.LANDING}>
                        <Nav.Link>Home</Nav.Link>
                    </LinkContainer>
                    <NavDropdown title="Account" id="basic-nav-dropdown">
                        <LinkContainer to={ROUTES.ACCOUNT}>
                            <NavDropdown.Item>Profile</NavDropdown.Item>
                        </LinkContainer>
                        <NavDropdown.Item><SignOutButton /></NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>*/


const NavigationNonAuth = () => {
    const classes = useStyles();
    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    <Link color="inherit" component={Link1} to="/" underline="none">
                        Find
                    </Link>
                </Typography>
                <Button color="inherit" component={Link1} to="/signin" >Login</Button>
            </Toolbar>
        </AppBar>
    );
};
    /*<Navbar collapseOnSelect bg="primary" expand="md" variant="dark">
        <Container>
            <LinkContainer to={ROUTES.LANDING}>
                <Navbar.Brand>Virgin Reward</Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <LinkContainer to={ROUTES.LANDING}>
                        <Nav.Link>Home</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to={ROUTES.SIGN_IN}>
                        <Nav.Link>Sign In</Nav.Link>
                    </LinkContainer>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>*/



export default Navigation;